import React from 'react'

import { Provider } from 'unstated'

import { navigate } from 'gatsby'

import get from 'lodash/get'

import { AuthProvider } from 'stores/auth'

import { GoogleOAuthProvider } from '@react-oauth/google'
import config from 'config'

import HomePage from './home'
import AppPage from './app'

import 'react-confirm-alert/src/react-confirm-alert.css'
class IndexPage extends React.Component {
  get shouldShowApp () {
    const isLoggedIn = this.props.authStore.isLoggedIn()
    const isRoot = this.props.uri === '/'
    const hasQuery = get(this.props, 'location.search')

    return isLoggedIn && isRoot && !hasQuery
  }

  componentDidMount () {
    if (this.shouldShowApp) {
      navigate('/app')
    }
  }

  render () {
    if (this.shouldShowApp) {
      return <AppPage {...this.props} />
    }

    return <HomePage {...this.props} />
  }
}

export default props => (
  <Provider>
    <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
      <AuthProvider>
        {store => {
          return <IndexPage {...props} authStore={store} />
        }}
      </AuthProvider>
    </GoogleOAuthProvider>
  </Provider>
)
